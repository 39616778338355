// @ts-nocheck
import _ from "lodash";
import { twJoin } from "tailwind-merge";
import UserContext from "@/contexts/UserContext";
import { useParchaApi } from "@/hooks/useParchaApi";
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Papa from "papaparse";
import { AgentsDropdownMenu } from "./V2_Home_Page/Queue";
import CaseSearch from "./TailwindComponents/CaseSearch";
import {
  Add,
  Close,
  UploadFileRounded,
  PlayCircleFilledRounded,
  PlayCircleRounded,
  Search,
  ViewListRounded,
  TaskRounded,
  NoteAddRounded,
  TimelapseRounded,
  CheckCircleRounded,
  ErrorRounded,
  CloseRounded,
} from "@mui/icons-material";
import { Dialog, Transition } from "@headlessui/react";
import SelfServeForm from "./SelfServeForm";
import { Agent, AgentJobInput, Endpoint, InputPayload } from "@/types";
import { TabContext, TabPanel } from "@mui/lab";
import InputWithOverlappingLabel from "./TailwindComponents/InputWithOverlappingLabel";
import { useDropzone } from "react-dropzone";
import { AntTab, AntTabList } from "./TailwindComponents/NewAgentChatSSE";
import { ErrorContext } from "@/contexts/ErrorContext";
import { Box, Button, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import DocumentValidationModal from "./DocumentValidationModal";
import { INCORPORATION_DOCUMENT_CHECK_ID, PROOF_OF_ADDRESS_CHECK_ID, EIN_DOCUMENT_CHECK_ID } from "@/constants/vars";
import DocumentRequestModal from "./DocumentRequestModal";
import COUNTRIES from "@/data/countries";
import AddressInput from "./TailwindComponents/AddressInput";
import { LoadScript } from "@react-google-maps/api";

const NewAgentsIndex = () => {
  const parchaApi = useParchaApi();
  const { agentKey } = useParams();
  const [agent, setAgent] = useState<Agent | null>(null);
  const userContext = useContext(UserContext);
  const { user, endpoints, hasRole, selectedEndpoint, setSelectedEndpoint } = userContext;
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [isRunAgentCaseModalOpen, setIsRunAgentCaseModalOpen] = useState(false);
  const [isAddCaseModalOpen, setIsAddCaseModalOpen] = useState(false);
  const [tabsValue, setTabsValue] = useState("0");
  const [fileMessage, setFileMessage] = useState("");
  const [acceptedFile, setAcceptedFile] = useState<File | null>(null);
  const [bulkRunCount, setBulkRunCount] = useState<number>(0);
  const [isUploadDocumentsModalOpen, setIsUploadDocumentsModalOpen] = useState(false);
  const [isDocumentRequestModalOpen, setIsDocumentRequestModalOpen] = useState(false);
  const [isSubmittingCaseInfoForm, setIsSubmittingCaseInfoForm] = useState<boolean>(false);
  const { setError } = useContext(ErrorContext);
  const businessNameFieldRef = useRef();
  const [selectedEnvType, setSelectedEnvType] = useState<string>();
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [filteredEndpoints, setFilteredEndpoints] = useState<typeof endpoints>([]);
  const [availableEnvTypes, setAvailableEnvTypes] = useState<string[]>([]);
  const [availableCustomers, setAvailableCustomers] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [coinbaseIDDAddress, setCoinbaseIDDAddress] = useState<{
    street1: string;
    street2: string;
    city: string;
    state: string;
    countryCode: string;
    postalCode: string;
  }>({
    street1: "",
    street2: "",
    city: "",
    state: "",
    countryCode: "",
    postalCode: "",
  });
  const [toastState, setToastState] = useState<{ show: boolean; status: string; message: string }>({
    show: false,
    status: "in_progress",
    message: "",
  });

  const Toast = () => {
    if (!toastState.show) return null;
    return (
      <div
        key="toast"
        className={twJoin(
          "w-1/5 h-[2.5rem] fixed top-1 left-1/2 -translate-x-1/2 z-50 p-4 flex items-center justify-between rounded-lg shadow-md",
          toastState.status === "in_progress"
            ? "bg-indigo-100 text-brand-purple"
            : toastState.status === "error"
              ? "bg-red-100 text-red-700"
              : "bg-green-100 text-green-700",
        )}
      >
        <div className="flex items-center gap-x-2">
          {toastState.status === "in_progress" ? (
            <TimelapseRounded sx={{ fontSize: "1rem" }} className="text-indigo-600" />
          ) : toastState.status === "error" ? (
            <ErrorRounded sx={{ fontSize: "1rem" }} className="text-red-600" />
          ) : (
            <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
          )}
          <span
            className={twJoin(
              "text-sm",
              toastState.status === "in_progress"
                ? "text-indigo-700"
                : toastState.status === "error"
                  ? "text-red-700"
                  : "text-green-700",
            )}
          >
            {toastState.message}
          </span>
        </div>
        <button
          onClick={() => setToastState((prev) => ({ ...prev, show: false }))}
          className="hover:opacity-70 transition-opacity"
        >
          <CloseRounded sx={{ fontSize: "1rem" }} />
        </button>
      </div>
    );
  };

  const isUserParcha = user?.userTenants?.some((tenant) => tenant.tenantName.includes("Parcha"));
  const getEnvTypeDisplayName = (envType: string) => {
    switch (envType) {
      case "demo":
        return "Fastbank Demo Agents";
      case "prod":
        return "Production Agents";
      case "pilot":
        return "Pilot Agents";
      case "dev":
        return "Internal Dev Endpoints";
      default:
        return envType;
    }
  };

  useEffect(() => {
    if (!endpoints) {
      setAvailableEnvTypes([]);
      setAvailableCustomers([]);
      setFilteredEndpoints([]);
      setSelectedEndpoint(null);
      return;
    }

    let filtered = endpoints;

    if (isUserParcha) {
      // Filter by envType
      const envTypes = [...new Set(endpoints.map((e) => e.envType))].sort((a, b) => {
        if (a === "demo") return -1;
        if (b === "demo") return 1;
        return a.localeCompare(b);
      });

      setAvailableEnvTypes(envTypes);

      filtered = endpoints.filter((e) => e.envType === selectedEnvType);

      // Filter by customer
      const customers = [
        ...new Set(filtered.flatMap((e) => e.tenants.map((t) => t.name)).filter((name) => name !== "Parcha")),
      ];

      setAvailableCustomers(customers);

      if (customers.length === 1 && !selectedCustomer) {
        setSelectedCustomer(customers[0]);
      } else if (customers.length > 1 && !selectedCustomer) {
        setSelectedCustomer("");
      }

      if (selectedCustomer) {
        filtered = filtered.filter((e) => e.tenants.some((t) => t.name === selectedCustomer));
      }
    } else {
      // For non-Parcha users, deduplicate and sort endpoints
      filtered = filtered
        .reduce((acc, current) => {
          const x = acc.find((item) => item.agentKey === current.agentKey);
          return x ? acc : [...acc, current];
        }, [])
        .sort((a, b) => {
          if (a.agentName.startsWith("(DEPRECATED)") && !b.agentName.startsWith("(DEPRECATED)")) return 1;
          if (!a.agentName.startsWith("(DEPRECATED)") && b.agentName.startsWith("(DEPRECATED)")) return -1;
          return a.agentName.localeCompare(b.agentName);
        });
    }

    setFilteredEndpoints(filtered);

    // Update selected endpoint and navigate if needed
    const newSelectedEndpoint = filtered.find((e) => e.agentKey === agentKey) || filtered[0];

    if (newSelectedEndpoint && (!selectedEndpoint || selectedEndpoint.agentKey !== newSelectedEndpoint.agentKey)) {
      if (newSelectedEndpoint.agentKey !== agentKey) {
        navigate(`/jobs/${newSelectedEndpoint.agentKey}`, { replace: true });
      } else {
        setSelectedEndpoint(newSelectedEndpoint);
      }
    }
  }, [endpoints, selectedEnvType, selectedCustomer, agentKey, isUserParcha]);

  useEffect(() => {
    if (endpoints && agentKey) {
      const currentAgent = endpoints.find((e) => e.agentKey === agentKey);
      if (currentAgent && isUserParcha) {
        setSelectedEnvType(currentAgent.envType);
        const customer = currentAgent.tenants.find((t) => t.name !== "Parcha")?.name;
        if (customer) setSelectedCustomer(customer);
      }
    }
  }, [endpoints, agentKey, isUserParcha]);

  // Handle envType change
  const handleEnvTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedEnvType(event.target.value as string);
    setSelectedCustomer(""); // Reset customer selection when envType changes
  };

  // Handle customer change
  const handleCustomerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCustomer(event.target.value as string);
  };

  const handleTabsChange = (_e: any, newValue: string) => {
    setTabsValue(newValue);
  };

  const endpoint = endpoints?.find((endpoint) => endpoint.agentKey === agentKey);

  useEffect(() => {
    if (!agentKey && userContext && userContext.endpoints && userContext.endpoints.length > 0) {
      navigate(`/jobs/${userContext.endpoints[0].agentKey}`);
    }
  }, [userContext?.endpoints, agentKey]);

  useEffect(() => {
    if (!agentKey || !selectedEndpoint) return;

    parchaApi.getSimpleAgent(selectedEndpoint.endpointUrl, agentKey).then((res) => {
      setAgent(res);
      userContext?.saveAgent(res);
    });
  }, [agentKey, selectedEndpoint]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file && file.type === "text/csv") {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            setBulkRunCount(results.data.length);
          },
        });
        setFileMessage(`File accepted: ${file.name}`);
        setAcceptedFile(file); // Store the accepted file in the state
      } else {
        setFileMessage("Only CSV files are allowed");
        setAcceptedFile(null); // Reset the accepted file state if not a CSV
      }
    },
    [agentKey, selectedEndpoint],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // Flutterwave didn't have access to run jobs with the current form.
  const isFlutterwaveMCC =
    selectedEndpoint?.agentName.includes("Flutterwave MCC") || selectedEndpoint?.agentName.includes("Cashfree MCC");

  // Enable Bulk Upload for Coinbase IDD and coinbase-v0-dev agents
  const isCoinbaseIDD =
    selectedEndpoint?.agentName.includes("Coinbase IDD") || selectedEndpoint?.agentKey.includes("coinbase-v0-dev");

  const documentValidationCheckIds = [
    INCORPORATION_DOCUMENT_CHECK_ID,
    PROOF_OF_ADDRESS_CHECK_ID,
    EIN_DOCUMENT_CHECK_ID,
  ];

  const agentCommandIds = agent?.steps.map((step) => step.command_id);

  const validationCheckIds = _.intersection(agentCommandIds, documentValidationCheckIds);

  const shouldEnableDocumentValidation = validationCheckIds.length > 0;

  // New Logic to only show the run job button if the agent is not public.
  const isPublic = selectedEndpoint?.isPublic;

  const validateForm = (formElement: HTMLFormElement, requiredFields: string[]) => {
    const errors: string[] = [];
    requiredFields.forEach((field) => {
      const element = formElement[field];
      if (!element.value.trim()) {
        element.classList.add("ring-red-500");
        errors.push(element.labels?.[0]?.textContent || field);
      } else {
        element.classList.remove("ring-red-500");
      }
    });
    setValidationErrors(errors);
    return errors.length === 0;
  };

  const handleJobRunSubmit = (event: SubmitEvent) => {
    event.preventDefault();
    if (!agentKey || !selectedEndpoint) return;

    const form = event.target as HTMLFormElement;

    if (isFlutterwaveMCC) {
      const requiredFields = ["business_name", "website", "business_purpose"];
      if (!validateForm(form, requiredFields)) {
        return;
      }
    } else if (isCoinbaseIDD) {
      const requiredFields = ["first_name", "last_name"];
      if (!validateForm(form, requiredFields)) {
        return;
      }
    }

    setIsSubmittingCaseInfoForm(true);

    if (isFlutterwaveMCC) {
      const target = form as typeof form & {
        business_name: { value: string };
        registered_business_name: { value: string };
        business_purpose: { value: string };
        website: { value: string };
        mcc: { value: string | undefined };
        country_of_operation: { value: string };
      };

      const inputData = {
        id: target.business_name.value.toLowerCase().replaceAll(" ", "_"),
        self_attested_data: {
          address_of_operation: {
            country_code: target.country_of_operation?.value ?? undefined,
          },
          business_name: target.business_name.value,
          business_purpose: target.business_purpose.value,
          mcc_code: target.mcc?.value ?? undefined,
          registered_business_name: target.registered_business_name?.value ?? undefined,
          website: target.website.value,
        },
      };

      const agentJobInput: AgentJobInput = {
        agent_key: agentKey,
        kyb_schema: inputData as InputPayload,
        run_in_parallel: true,
      };

      parchaApi
        .enqueueAgent(selectedEndpoint?.endpointUrl, agentJobInput)
        .then((res) => {
          const jobId = res.job_id;
          window.location.href = `/jobs/${agentKey}/${jobId}`;
        })
        .catch((err) => setError(err))
        .finally(() => {
          setIsAddCaseModalOpen(false);
          setIsSubmittingCaseInfoForm(false);
        });
    } else if (isCoinbaseIDD) {
      const target = form as typeof form & {
        first_name: { value: string };
        last_name: { value: string };
        middle_name: { value: string };
        date_of_birth: { value: string };
        email: { value: string };
        country_of_residence: { value: string };
        phone: { value: string };
      };
      const inputData = {
        id: [target.first_name.value, target.middle_name.value, target.last_name.value]
          .filter((name) => name?.trim())
          .join("_")
          .toLowerCase()
          .replaceAll(" ", "_"),
        self_attested_data: {
          first_name: target.first_name.value,
          last_name: target.last_name.value,
          middle_name: target.middle_name.value,
          date_of_birth: target.date_of_birth.value,
          email: target.email.value,
          phone: target.phone.value,
          country_of_residence: target.country_of_residence.value,
          address: {
            street_1: coinbaseIDDAddress.street1,
            street_2: coinbaseIDDAddress.street2,
            city: coinbaseIDDAddress.city,
            state: coinbaseIDDAddress.state,
            country_code: coinbaseIDDAddress.countryCode,
            postal_code: coinbaseIDDAddress.postalCode,
          },
        },
      };

      const agentJobInput: AgentJobInput = {
        agent_key: agentKey,
        kyc_schema: inputData as InputPayload,
        run_in_parallel: true,
      };

      parchaApi
        .enqueueAgent(selectedEndpoint?.endpointUrl, agentJobInput, "kyc")
        .then((res) => {
          const jobId = res.job_id;
          window.location.href = `/jobs/${agentKey}/${jobId}`;
        })
        .catch((err) => setError(err))
        .finally(() => {
          setIsAddCaseModalOpen(false);
          setIsSubmittingCaseInfoForm(false);
        });
    }
  };

  const handleCoinbaseIDDAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCoinbaseIDDAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const downloadFile = ({ data, fileName, fileType }: { data: string[]; fileName: string; fileType: string }) => {
    const blob = new Blob(data, { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleDownloadCSVTemplateClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    let headers = [""];

    if (isFlutterwaveMCC) {
      headers = ["business_name,website,business_purpose,mcc_code"];
    } else if (isCoinbaseIDD) {
      headers = [
        "id,first_name,last_name,middle_name,date_of_birth,email,phone,country_of_residence,street_1,street_2,city,state,postal_code,country_code,",
      ];
    }

    downloadFile({
      data: headers,
      fileName: "parcha_bulk_case_upload_template.csv",
      fileType: "text/csv",
    });
  };

  useEffect(() => {
    // if modal is closing, reset the state
    if (!isAddCaseModalOpen) {
      setAcceptedFile(null);
      setBulkRunCount(0);
      setFileMessage("");
      setTabsValue("0");
    }
  }, [isAddCaseModalOpen]);

  const handleCSVJobsRunSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (selectedEndpoint && acceptedFile) {
      setIsSubmittingCaseInfoForm(true);

      parchaApi
        .enqueueFromCSV(selectedEndpoint.endpointUrl, acceptedFile as File, selectedEndpoint.agentKey)
        .then((res) => {
          if (res.status === "ok") {
            setToastState({ show: true, status: "in_progress", message: res.message });
          } else {
            setToastState({ show: true, status: "error", message: "Failed to upload CSV file" });
          }
        })
        .catch((err) => {
          setToastState({ show: true, status: "error", message: "Failed to upload CSV file" });
        })
        .finally(() => {
          setIsAddCaseModalOpen(false);
          setIsSubmittingCaseInfoForm(false);
          setTimeout(() => {
            setToastState({ show: false, status: "in_progress", message: "" });
          }, 10000);
        });
    }
  };

  function a11yProps(index: number) {
    return {
      id: `run-case-tab-${index}`,
      "aria-controls": `run-case-tabpanel-${index}`,
    };
  }

  return (
    <div className="h-full relative">
      <Toast />
      <div className="w-full h-[3.75rem] border border-b-slate-200 flex items-center px-4 gap-2">
        {isUserParcha && (
          <div className="flex items-center gap-x-2">
            {availableEnvTypes.length > 1 && (
              <FormControl size="small" style={{ minWidth: "200px" }}>
                <InputLabel id="env-type-label">Agent Type</InputLabel>
                <Select
                  sx={{
                    fontSize: "0.875rem",
                    height: "2rem",
                    borderRadius: "0.5rem",
                    borderColor: "##e5e5e5",
                  }}
                  labelId="env-type-label"
                  value={selectedEnvType}
                  label="Agent Type"
                  onChange={handleEnvTypeChange}
                >
                  {availableEnvTypes.map((envType) => (
                    <MenuItem key={envType} value={envType}>
                      {getEnvTypeDisplayName(envType)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {availableCustomers.length > 1 && (
              <FormControl size="small" style={{ minWidth: "200px" }}>
                <InputLabel id="customer-label">Customer</InputLabel>
                <Select
                  sx={{ fontSize: "0.875rem", height: "2rem", borderRadius: "0.5rem", borderColor: "#e5e5e5" }}
                  labelId="customer-label"
                  value={selectedCustomer}
                  label="Customer"
                  onChange={handleCustomerChange}
                >
                  {availableCustomers.map((customer) => (
                    <MenuItem key={customer} value={customer}>
                      {customer}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
        )}
        <div className="flex items-center gap-x-4">
          {filteredEndpoints.length > 0 && (
            <AgentsDropdownMenu
              items={filteredEndpoints.map((agent) => ({ label: agent.agentName, url: `/jobs/${agent.agentKey}` }))}
              selectedItem={{
                label: selectedEndpoint?.agentName || filteredEndpoints[0].agentName,
                url: `/jobs/${selectedEndpoint?.agentKey || filteredEndpoints[0].agentKey}`,
              }}
            />
          )}
          <div className="flex items-center gap-x-2">
            {selectedEndpoint && agent && !isFlutterwaveMCC && !isCoinbaseIDD && !isPublic && hasRole("analyst") && (
              <button
                type="button"
                className="box-content text-sm relative flex justify-center items-center rounded-md bg-brand-purple p-2 h-4 border border-solid border-brand-purple text-white focus:z-10 "
                onClick={() => setIsRunAgentCaseModalOpen((prev) => !prev)}
              >
                <PlayCircleRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                <span className="line-clamp-1">Run a Job</span>
              </button>
            )}
            {shouldEnableDocumentValidation && hasRole("analyst") && (
              <>
                <button
                  type="button"
                  className="box-content text-sm relative flex justify-center items-center rounded-md p-2 h-4 border border-solid border-slate-300 text-slate-900 focus:z-10 "
                  onClick={() => setIsUploadDocumentsModalOpen((prev) => !prev)}
                >
                  <TaskRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                  <span className="line-clamp-1">Verify Document</span>
                </button>
                {isUserParcha && (
                  <button
                    type="button"
                    className="box-content text-sm relative flex justify-center items-center rounded-md p-2 h-4 border border-solid border-slate-300 text-slate-900 focus:z-10 "
                    onClick={() => setIsDocumentRequestModalOpen((prev) => !prev)}
                  >
                    <NoteAddRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                    <span className="line-clamp-1">Request Document</span>
                  </button>
                )}
              </>
            )}

            {(isFlutterwaveMCC || isCoinbaseIDD) && (
              <button
                type="button"
                className="box-content text-sm relative flex justify-center items-center rounded-md bg-brand-purple p-2 h-4 border border-solid border-brand-purple text-white focus:z-10 "
                onClick={() => setIsAddCaseModalOpen((prev) => !prev)}
              >
                <Add sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                <span className="line-clamp-1">Add Cases</span>
              </button>
            )}

            {endpoint && (
              <button
                onClick={() => setShowSearch(!showSearch)}
                className="box-content flex justify-center items-center border border-solid border-slate-300 rounded-md w-4 h-4 p-2 hover:border-slate-400"
              >
                <Search sx={{ fontSize: "1rem" }} className="text-slate-900" />
              </button>
            )}
          </div>
        </div>
      </div>
      {selectedEndpoint && (
        <CaseSearch endpoint={selectedEndpoint} show={showSearch} setShow={() => setShowSearch(!showSearch)} />
      )}
      <Transition.Root show={isRunAgentCaseModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={setIsRunAgentCaseModalOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:w-1/2">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded bg-white text-slate-700 hover:text-slate-900 p-.5 focus:outline-none focus:ring-1 focus:ring-brand-purple focus:ring-offset-1"
                      onClick={() => setIsRunAgentCaseModalOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <Close sx={{ fontSize: "1rem" }} className="h-2 w-2" aria-hidden="true" />
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="flex gap-x-3 items-center text-base font-semibold leading-6 text-slate-900 px-5 my-4"
                  >
                    <ViewListRounded sx={{ fontSize: "1.5rem" }} className="text-slate-400 h-6 w-6" />
                    Inputs for Case
                  </Dialog.Title>
                  <div className="mt-5 text-sm">
                    <SelfServeForm
                      agent={agent}
                      selectedEndpoint={selectedEndpoint}
                      onSubmitCallback={(newJobId: string) => {
                        window.location.href = `/jobs/${selectedEndpoint?.agentKey}/${newJobId}`;
                        setIsRunAgentCaseModalOpen(false);
                      }}
                      onCloseCallback={() => setIsRunAgentCaseModalOpen(false)}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Add Case Modal */}
      <Transition.Root show={isAddCaseModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={setIsAddCaseModalOpen} initialFocus={businessNameFieldRef}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:w-2/5">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded bg-white text-slate-700 hover:text-slate-900 p-.5 focus:outline-none focus:ring-1 focus:ring-brand-purple focus:ring-offset-1"
                      onClick={() => setIsAddCaseModalOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <Close sx={{ fontSize: "1rem" }} className="h-2 w-2" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-5 text-sm">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-slate-900 px-5 my-5">
                      Enter a case to run
                    </Dialog.Title>
                    <TabContext value={tabsValue}>
                      <>
                        <AntTabList
                          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                          className="text-sm"
                          value={tabsValue}
                          aria-label="run case method selector"
                          onChange={handleTabsChange}
                        >
                          <AntTab sx={{ flex: "1 1 100%" }} label="Case Information" {...a11yProps(0)} value="0" />
                          <AntTab sx={{ flex: "1 1 100%" }} label="Bulk Upload Cases" {...a11yProps(1)} value="1" />
                        </AntTabList>
                      </>

                      <TabPanel value="0" sx={{ width: "100%" }}>
                        {isFlutterwaveMCC && (
                          <form
                            onSubmit={handleJobRunSubmit}
                            autoComplete="off"
                            className="w-full mt-4 flex flex-col gap-y-5"
                          >
                            <div className="flex gap-x-2 gap-y-6 w-full">
                              <InputWithOverlappingLabel
                                required
                                fieldName="business_name"
                                label="Business Name"
                                id="business-name"
                                name="business_name"
                                className="grow"
                              />
                            </div>

                            <div className="grid grid-cols-6 gap-x-3">
                              <InputWithOverlappingLabel
                                required
                                fieldName="website"
                                label="Website URL"
                                id="website"
                                name="website"
                                className="col-span-3"
                              />

                              <InputWithOverlappingLabel
                                fieldName="mcc"
                                label="MCC Code"
                                id="mcc"
                                name="mcc"
                                className={"col-span-3"}
                              />
                            </div>

                            <div className="relative">
                              <label
                                htmlFor="businessPurpose"
                                className="absolute -top-2 left-2 bg-white px-1.5 block text-xs text-slate-900"
                              >
                                Business Description
                              </label>
                              <textarea
                                rows={4}
                                name="business_purpose"
                                id="business-purpose"
                                className="box-border block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-brand-purple text-xs sm:leading-6"
                              />
                            </div>

                            <div className="flex items-center justify-between">
                              <button
                                type="submit"
                                className="place-self-end w-fit inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm rounded-md text-white bg-brand-purple hover:bg-brand-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-purple"
                              >
                                <PlayCircleFilledRounded
                                  sx={{ fontSize: "1rem" }}
                                  className="mr-1 h-4 w-4"
                                  aria-hidden="true"
                                />
                                <span className="line-clamp-1">Run Case</span>
                              </button>
                            </div>
                          </form>
                        )}
                        {isCoinbaseIDD && (
                          <LoadScript googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY} libraries={["places"]}>
                            <form
                              onSubmit={handleJobRunSubmit}
                              autoComplete="off"
                              className="w-full mt-4 flex flex-col gap-y-5"
                            >
                              <div className="grid grid-cols-6 gap-4">
                                <InputWithOverlappingLabel
                                  required
                                  fieldName="first_name"
                                  label="First Name"
                                  id="first-name"
                                  name="first_name"
                                  className="col-span-2"
                                />
                                <InputWithOverlappingLabel
                                  fieldName="middle_name"
                                  label="Middle Name"
                                  id="middle-name"
                                  name="middle_name"
                                  className="col-span-2"
                                />
                                <InputWithOverlappingLabel
                                  required
                                  fieldName="last_name"
                                  label="Last Name"
                                  id="last-name"
                                  name="last_name"
                                  className="col-span-2"
                                />
                                <InputWithOverlappingLabel
                                  fieldName="email"
                                  label="Email"
                                  id="email"
                                  name="email"
                                  className="col-span-4"
                                />
                                <InputWithOverlappingLabel
                                  fieldName="phone"
                                  label="Phone"
                                  id="phone"
                                  name="phone"
                                  className="col-span-2"
                                />
                                <InputWithOverlappingLabel
                                  fieldName="date_of_birth"
                                  label="Date of Birth"
                                  id="date-of-birth"
                                  name="date_of_birth"
                                  className="col-span-3"
                                  type="text"
                                  onFocus={(e) => (e.target.type = "date")}
                                  onBlur={(e) => (e.target.type = "text")}
                                />
                                <div className="col-span-3">
                                  <div className="relative">
                                    <label className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-slate-900 w-auto truncate">
                                      Country of Residence
                                    </label>
                                    <select
                                      id="country_of_residence"
                                      name="country_of_residence"
                                      placeholder="Country of Residence"
                                      className="block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
                                      defaultValue={COUNTRIES.find((c) => c.name === "United States")?.value}
                                    >
                                      {COUNTRIES.map((country) => (
                                        <option key={country.value} value={country.value}>
                                          {country.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-span-6 flex flex-col gap-y-5">
                                  <label className="text-xs font-semibold text-slate-900">Address</label>
                                  <AddressInput
                                    address={coinbaseIDDAddress}
                                    onAddressChange={handleCoinbaseIDDAddressChange}
                                    onPlaceChange={(address) => {
                                      setCoinbaseIDDAddress(address);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="flex items-center justify-between self-end">
                                <button
                                  type="submit"
                                  className="place-self-end w-fit inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm rounded-md text-white bg-brand-purple hover:bg-brand-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-purple"
                                >
                                  <PlayCircleFilledRounded
                                    sx={{ fontSize: "1rem" }}
                                    className="mr-1 h-4 w-4"
                                    aria-hidden="true"
                                  />
                                  <span className="line-clamp-1">Run Case</span>
                                </button>
                              </div>
                            </form>
                          </LoadScript>
                        )}
                      </TabPanel>
                      <TabPanel value="1" sx={{ width: "100%" }}>
                        <Box
                          {...getRootProps()}
                          sx={{ backgroundColor: "#fcfcfc" }}
                          style={{
                            padding: "1.25rem",
                            border: ".125rem dashed #eeeeee",
                            textAlign: "center",
                            width: "100%",
                            height: "10rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <input {...getInputProps()} type="file" accept="text/csv" />
                          {isDragActive ? (
                            <p>Drop CSV ...</p>
                          ) : (
                            <p>Drag & Drop a CSV file here, or click to select a file</p>
                          )}
                          {fileMessage && <p className="font-medium">{fileMessage}</p>}
                        </Box>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", paddingTop: 2 }}>
                          <Button
                            sx={{ textTransform: "none" }}
                            className="text-sm"
                            variant="text"
                            onClick={handleDownloadCSVTemplateClick}
                          >
                            Download Template
                          </Button>
                          <Button
                            className="text-sm"
                            onClick={handleCSVJobsRunSubmit}
                            variant="contained"
                            sx={{ textTransform: "none" }}
                            disabled={isSubmittingCaseInfoForm || bulkRunCount === 0}
                            startIcon={<PlayCircleFilledRounded sx={{ height: "1rem" }}></PlayCircleFilledRounded>}
                          >
                            {`Run ${bulkRunCount > 0 ? `${bulkRunCount} ` : ""}Case${bulkRunCount > 1 ? "s" : ""}`}
                          </Button>
                        </Box>
                      </TabPanel>
                    </TabContext>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {agent && endpoint && (
        <DocumentValidationModal
          agent={agent}
          endpoint={selectedEndpoint}
          show={isUploadDocumentsModalOpen}
          setShow={setIsUploadDocumentsModalOpen}
          validationCheckIds={validationCheckIds}
        />
      )}
      {agent && endpoint && (
        <DocumentRequestModal
          agent={agent}
          endpoint={selectedEndpoint}
          show={isDocumentRequestModalOpen}
          setShow={setIsDocumentRequestModalOpen}
          validationCheckIds={validationCheckIds}
        />
      )}
      <Outlet />
    </div>
  );
};

export default NewAgentsIndex;
